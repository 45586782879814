.App {
  text-align: center;
}

@font-face {
  font-family: revicons;
  fallback: fallback;
  src: url("revicons.93d74edb.woff") format("woff"), url("revicons.6716ab81.ttf") format("ttf"), url("revicons.80a7204d.eot") format("ttf");
}

.react-multi-carousel-list {
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.react-multi-carousel-track {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
  flex-direction: row;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
}

.react-multiple-carousel__arrow {
  z-index: 1000;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  background: #00000080;
  border: 0;
  border-radius: 35px;
  outline: 0;
  transition: all .5s;
  position: absolute;
}

.react-multiple-carousel__arrow:hover {
  background: #000c;
}

.react-multiple-carousel__arrow:before {
  color: #fff;
  text-align: center;
  z-index: 2;
  font-family: revicons;
  font-size: 20px;
  display: block;
  position: relative;
}

.react-multiple-carousel__arrow:disabled {
  cursor: default;
  background: #00000080;
}

.react-multiple-carousel__arrow--left {
  left: calc(4% + 1px);
}

.react-multiple-carousel__arrow--left:before {
  content: "";
}

.react-multiple-carousel__arrow--right {
  right: calc(4% + 1px);
}

.react-multiple-carousel__arrow--right:before {
  content: "";
}

.react-multi-carousel-dot-list {
  text-align: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.react-multi-carousel-dot button {
  width: 12px;
  height: 12px;
  opacity: 1;
  box-shadow: none;
  cursor: pointer;
  border: 2px solid gray;
  border-radius: 50%;
  outline: 0;
  margin: 0 6px 0 0;
  padding: 0;
  transition: background .5s;
  display: inline-block;
}

.react-multi-carousel-dot button:hover:active, .react-multi-carousel-dot--active button {
  background: #080808;
}

.react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .react-multi-carousel-item {
    flex-shrink: 0 !important;
  }

  .react-multi-carousel-track {
    overflow: visible !important;
  }
}

[dir="rtl"].react-multi-carousel-list {
  direction: rtl;
}

.rtl.react-multiple-carousel__arrow--right {
  left: calc(4% + 1px);
  right: auto;
}

.rtl.react-multiple-carousel__arrow--right:before {
  content: "";
}

.rtl.react-multiple-carousel__arrow--left {
  left: auto;
  right: calc(4% + 1px);
}

.rtl.react-multiple-carousel__arrow--left:before {
  content: "";
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("background.b74e9675.png");
  margin: 0;
  font-family: Chelsea Market, cursive;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

/*# sourceMappingURL=index.2b169eab.css.map */
